<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base" v-if="is_filter">
      <vx-card title="Filter">
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
            <browse-route class="w-full" name="route_id" label="Route" v-model="filterData.route_id" @input='changeRoute'></browse-route>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
            <browse-commodity class="w-full" name="commodity_id" label="Commodity" v-model="filterData.commodity_id" @on-change="changeCommodity"></browse-commodity>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
            <browse-vehicle-type class="w-full" name="vehicle_type_id" label="Vehicle Type" v-model="filterData.vehicle_type_id" @on-change="changeVehicleType"></browse-vehicle-type>
          </div>
        </div>
        <div class="vx-col w-full flex mt-3">
          <vs-button color="warning" @click="resetFilter()">Reset</vs-button>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <vx-card title="Price Lists">
        <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
          <template slot="header">
            <vs-button color="success" class='ml-1 mr-1' @click="is_filter = !is_filter">Filter</vs-button>&nbsp;
            <vs-button color="primary" :to="contract_id ? {name: 'contracts-create-pricelist', params:{'contract_id':contract_id}} : {name: 'price_lists-create'}">Add New</vs-button>
          </template>
          <template slot="thead">
            <vs-th sort-key="name">Name</vs-th>
            <vs-th sort-key="route">Route</vs-th>
            <vs-th sort-key="mode">Mode</vs-th>
            <vs-th sort-key="commodity">Commodity</vs-th>
            <vs-th sort-key="vehicle_type">Vehicles</vs-th>
            <vs-th sort-key="price_tonase">Price per Kg</vs-th>
            <vs-th sort-key="price_volume">Price per m<sup>3</sup></vs-th>
            <vs-th sort-key="price_item">Price per Item</vs-th>
            <vs-th sort-key="price_full">Full Price</vs-th>
            <vs-th sort-key="created_at"></vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.name">
                <router-link :to="{name:'price_lists-show', params:{id: tr.id}}">{{tr.name}}</router-link>
              </vs-td>
              <vs-td label="Route" :data="tr.route">{{tr.route}}</vs-td>
              <vs-td label="Mode" :data="tr.mode">{{tr.mode}}</vs-td>
              <vs-td label="Commodity" :data="tr.commodity">{{tr.commodity}}</vs-td>
              <vs-td label="Vehicles" :data="tr.vehicle_type">{{tr.vehicle_type}}</vs-td>
              <vs-td label="Price per Kg" :data="tr.price_tonase" class='text-right'>{{tr.price_tonase|numFormat}}</vs-td>
              <vs-td label="Price per m<sup>3</sup>" :data="tr.price_volume" class='text-right'>{{tr.price_volume|numFormat}}</vs-td>
              <vs-td label="Price per Item" :data="tr.price_item" class='text-right'>{{tr.price_item|numFormat}}</vs-td>
              <vs-td label="Full Price" :data="tr.price_full" class='text-right'>{{tr.price_full|numFormat}}</vs-td>
              <vs-td :data="tr.id" width="10">
                <div class="flex">
                  <vs-button size="small" type="line" :to="{name:'price_lists-show', params:{id: tr.id}}" icon-pack="feather" icon="icon-folder"></vs-button>
                  <vs-button size="small" color='success' type="line" :to="{name:'price_lists-edit', params:{id: tr.id}}" icon-pack="feather" icon="icon-edit"></vs-button>
                  <vs-button size="small" type="line" color='danger' @click="deletes(tr.id)" icon-pack="feather" icon="icon-trash"></vs-button>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
      </vx-card>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import BrowseRoute from '@browse/Route.vue'
import BrowseVehicleType from '@browse/VehicleType.vue'
import BrowseCommodity from '@browse/Commodity.vue'

export default {
  props : ['contract_id'],
  components:{
    BrowseRoute,
    BrowseVehicleType,
    BrowseCommodity
  },
  data(){
    return {
      filterData : {},
      is_filter : false,
      idDelete: null,
      currentPage: 1,
      search:null,
      sortKey:"created_at",
      sortVal:"desc"
    }
  },
  watch:{
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      data: state => state.price_lists.rows
    })
  },
  methods:{
    ...mapActions({
      dispatchIndex: 'price_lists/index',
      dispatchDestroy: 'price_lists/destroy'
    }),
    async getData(){
      let payload = {
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal
      }
      if(this.contract_id) {
        payload.contract_id = this.contract_id
      }
      payload = Object.assign(payload, this.filterData)
      await this.dispatchIndex(payload)
    },
    async confirmDelete(){
      try {
        await this.dispatchDestroy(this.idDelete)
        this.getData()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been deleted successfully`,
          color: `primary`
        })
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
    deletes(id){
      this.idDelete = id
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmDelete
      })
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    },
    async changeRoute(data){
      this.filterData.route_id = data
      await this.getData();
    },
    async changeCommodity(data){
      this.filterData.commodity_id = data.id
      await this.getData();
    },
    async changeVehicleType(data){
      this.filterData.vehicle_type_id = data.id
      await this.getData();
    },
    async resetFilter() {
      this.filterData = {}
      await this.getData()
    }
  },
  async mounted(){
    await this.getData()
  }
}
</script>

<style>

</style>